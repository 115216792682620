const intelligent = () => import("../views/intelligent/index.vue");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/intelligentA",
    component: layout,
    name: "intelligentA",
    children: [
      { path: "/intelligent", component: intelligent, name: "intelligent" },
    ],
  },
];
