const studentAttendanceRecords = () =>
  import("../views/classroom/student/studentAttendanceRecords");
const studentAttRecordsbreaktime = () =>
  import("../views/classroom/student/studentAttRecordsbreaktime");
const studentSportsTwoAndOne = () =>
  import("../views/classroom/student/studentSportsTwoAndOne");
const homework = () => import("../views/classroom/student/homework");
import("../views/classroom/student/studentSportsTwoAndOne");
const classroomrecords = () =>
  import("../views/classroom/classroomrecords/index");
const classroomrecordsdetail = () =>
  import("../views/classroom/classroomrecords/detail");
const preparation = () =>
  import("../views/classroom/classroomrecords/preparation");
const classstyle = () => import("../views/classroom/classroomrecords/style");
const attendance = () =>
  import("../views/classroom/classroomrecords/attendance");
const classreport = () => import("../views/classroom/classroomrecords/report");
const singleclassreport = () =>
  import("../views/classroom/classroomrecords/single");

const classroom = () => import("../views/classroom/index.vue");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/classroomA",
    component: layout,
    name: "classroomA",
    children: [
      {
        path: "/classroom",
        component: classroom,
        name: "classroom",
      },
      {
        path: "/classroomrecords",
        component: classroomrecords,
        name: "classroomrecords",
      },
      {
        path: "/singleclassreport",
        component: singleclassreport,
        name: "singleclassreport",
      },
      {
        path: "/classroomrecordsdetail",
        component: classroomrecordsdetail,
        name: "classroomrecordsdetail",
        children: [
          {
            path: "/preparation",
            component: preparation,
            name: "preparation",
          },
          {
            path: "/classstyle",
            component: classstyle,
            name: "classstyle",
          },
          {
            path: "/attendance",
            component: attendance,
            name: "attendance",
          },
        ],
      },
      { path: "/classreport", component: classreport, name: "classreport" },
      {
        path: "/studentAttendanceRecords",
        component: studentAttendanceRecords,
        name: "studentAttendanceRecords",
      },

      {
        path: "/studentAttRecordsbreaktime",
        component: studentAttRecordsbreaktime,
        name: "studentAttRecordsbreaktime",
      },
      {
        path: "/studentSportsTwoAndOne",
        component: studentSportsTwoAndOne,
        name: "studentSportsTwoAndOne",
      },
      {
        path: "/homework",
        component: homework,
        name: "homework",
      },
    ],
  },
];
