const tasklist = () => import("../views/task/task-list");
const task_rate = () => import("../views/task/task-rate");
const assignment = () => import("../views/task/assignment");

const task_detail = () => import("../views/task/task-detail");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/tasklistA",
    component: layout,
    name: "tasklistA",
    children: [
      { path: "/tasklist", component: tasklist, name: "tasklist" },
      { path: "/assignment", component: assignment, name: "assignment" },
      { path: "/taskdetail", component: task_detail, name: "taskdetail" },
      { path: "/task_rate", component: task_rate, name: "task_rate" },
    ],
  },
];
