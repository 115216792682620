const resources = () => import("../views/resources/index.vue");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/resourcesA",
    component: layout,
    name: "resourcesA",
    children: [{ path: "/resources", component: resources, name: "resources" }],
  },
];
