const mynotice = () => import("../views/mynotice/index.vue");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/mynoticeA",
    component: layout,
    name: "mynoticeA",
    children: [{ path: "/mynotice", component: mynotice, name: "mynotice" }],
  },
];
