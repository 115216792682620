const attainment = () => import("../views/attainment/index.vue");
const studentPhysical = () =>
  import(
    /* webpackChunkName: "physical" */ "../views/attainment/studentPhysical.vue"
  );

// 班级
const classExamination = () =>
  import(
    /* webpackChunkName: "physical" */ "../views/attainment/examination/class.vue"
  );

// 学生
const studentExamination = () =>
  import(
    /* webpackChunkName: "physical" */ "../views/attainment/examination/student.vue"
  );
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/attainmentA",
    component: layout,
    name: "attainmentA",
    children: [
      {
        path: "/attainment",
        component: attainment,
        name: "attainment",
      },
      {
        path: "/studentPhysical",
        component: studentPhysical,
        name: "studentPhysical",
      },
      {
        path: "/classExamination",
        component: classExamination,
        name: "classExamination",
      },
      {
        path: "/studentExamination",
        component: studentExamination,
        name: "studentExamination",
      },
    ],
  },
];
