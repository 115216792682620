import Vue from 'vue'

import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
		//公共的变量，这里的变量不能随便修改，只能通过触发mutations的方法才能改变
		isCollapse:false,
		quoteclass:[],
		quoteid:[],
		ziyuanbaoid:[],
		genwolian:[],
		cunchuziy:[],
		ccgenwolian:[],
		duibiId1:[],
		duibiId2:[],
		source_type:1
	},
    mutations: {
		//相当于同步的操作
		changeIsCollapse(state, isCollapse){
			state.isCollapse = isCollapse
		},
		//操作引用数据
		quoteclass(state, quoteclass){
			state.quoteclass = quoteclass
			// console.log(quoteclass,'quoteclass');
		},
		quoteid(state, quoteid){
			state.quoteid = quoteid
			// console.log(quoteid,'quoteid');
		},
		genwolian(state, genwolian){
			state.genwolian = genwolian
			// console.log(quoteid,'quoteid');
		},
		sourceType(state, sourceType){
			state.source_type = sourceType
		},
		ziyuanbaoid(state, ziyuanbaoid){
			state.ziyuanbaoid = ziyuanbaoid
			// console.log(ziyuanbaoid,'ziyuanbaoid');
		},
		cunchuziy(state, cunchuziy){
			state.cunchuziy = cunchuziy
		},
		ccgenwolian(state, ccgenwolian){
			state.ccgenwolian = ccgenwolian
		},
		//对比引用
		duibiId1(state, duibiId1){
			state.duibiId1 = duibiId1
		},
		duibiId2(state, duibiId2){
			state.duibiId2 = duibiId2
		}
	},
    actions: {
		//相当于异步的操作,不能直接改变state的值，只能通过触发mutations的方法才能改变
	}
})
export default store