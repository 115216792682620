const games = () => import("../views/games/index.vue");
const competition = () => import("../views/games/competition.vue");
const realTimeScore = () => import("../views/games/realTimeScore");
const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/gamesA",
    component: layout,
    name: "gamesA",
    children: [
      { path: "/games", component: games, name: "games" },
      {
        path: "/competition",
        component: competition,
        name: "competition",
      },
      {
        path: "/realTimeScore",
        component: realTimeScore,
        name: "realTimeScore",
      },
    ],
  },
];
