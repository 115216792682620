import Vue from "vue";
import VueRouter from "vue-router";
const Login = () => import("../views/login.vue");
const Home = () => import("../views/home.vue");
const index = () => import("../views/index/index.vue");
// const classroom = () => import("../views/classroom/index.vue");
const job = () => import("../views/job/index.vue");
// const attainment = () => import("../views/attainment/index.vue");
const studentPhysical = () =>
  import(
    /* webpackChunkName: "physical" */ "../views/attainment/studentPhysical.vue"
  );

const PersonalCenter = () => import("../views/PersonalCenter/index.vue");
const revise = () => import("../views/PersonalCenter/revise");
// const intelligent = () => import("../views/intelligent/index.vue");
// const resources = () => import("../views/resources/index.vue");
const resourcesDetails = () => import("../views/resources/detail");
// const games = () => import("../views/games/index.vue");
const realTimeLLine = () => import("../views/games/realTimeLLine");
// const realTimeScore = () => import("../views/games/realTimeScore");

const competition = () => import("../views/games/competition.vue");
// const mynotice = () => import("../views/mynotice/index.vue");
const pointmap = () => import("../views/games/pointmap.vue");
// const tasklist = () => import("../views/task/task-list");
// const task_rate = () => import("../views/task/task-rate");
// const assignment = () => import("../views/task/assignment");

// const task_detail = () => import("../views/task/task-detail");

// // 班级
// const classExamination = () =>
//   import(
//     /* webpackChunkName: "physical" */ "../views/attainment/examination/class.vue"
//   );

// // 学生
// const studentExamination = () =>
//   import(
//     /* webpackChunkName: "physical" */ "../views/attainment/examination/student.vue"
//   );
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
}; // 重复点击路由报错的问题
Vue.use(VueRouter);
import evaluation from "./evaluation";
import classroomRoute from "./classroom";
import tasklist from "./task";
import attainment from "./attainment";
import game from "./game";
import resources from "./resources";
import mynotice from "./mynotice";
import intelligentA from "./intelligent";
const routes = [
  ...mynotice,
  ...resources,
  ...evaluation,
  ...classroomRoute,
  ...tasklist,
  ...attainment,
  ...game,
  ...intelligentA,
  { path: "/", redirect: "login" },
  { path: "/login", component: Login, name: "login" },
  {
    path: "/home",
    component: Home,
    name: "home",
    children: [
      // { path: "/tasklist", component: tasklist, name: "tasklist" },
      // { path: "/assignment", component: assignment, name: "assignment" },
      // { path: "/taskdetail", component: task_detail, name: "taskdetail" },
      // { path: "/task_rate", component: task_rate, name: "task_rate" },
      {
        path: "/index",
        component: index,
        name: "index",
      },
      // {
      //   path: "/classroom",
      //   component: classroom,
      //   name: "classroom",
      // },
      {
        path: "/job",
        component: job,
        name: "job",
      },
      // {
      //   path: "/games",
      //   component: games,
      //   name: "games",
      // },
      {
        path: "/PersonalCenter",
        component: PersonalCenter,
        name: "PersonalCenter",
      },
      {
        path: "/revise",
        component: revise,
        name: "revise",
      },
      // {
      //   path: "/competition",
      //   component: competition,
      //   name: "competition",
      // },
      {
        path: "/realTimeLLine",
        component: realTimeLLine,
        name: "realTimeLLine",
      },
      // {
      //   path: "/realTimeScore",
      //   component: realTimeScore,
      //   name: "realTimeScore",
      // },

      { path: "/pointmap", component: pointmap, name: "pointmap" },
      // { path: "/mynotice", component: mynotice, name: "mynotice" },
      // {
      //   path: "/attainment",
      //   component: attainment,
      //   name: "attainment",
      // },

      // {
      //   path: "/classExamination",
      //   component: classExamination,
      //   name: "classExamination",
      // },
      // {
      //   path: "/studentExamination",
      //   component: studentExamination,
      //   name: "studentExamination",
      // },
      // {
      //   path: "/studentPhysical",
      //   component: studentPhysical,
      //   name: "studentPhysical",
      // },

      // {
      //   path: "/intelligent",
      //   component: intelligent,
      //   name: "intelligent",
      // },
      // {
      //   path: "/resources",
      //   component: resources,
      //   name: "resources",
      // },
      {
        path: "/resourcesDetails",
        component: resourcesDetails,
        name: "resourcesDetails",
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // scrollBehavior(to, from, savedPosition) {
  //   // return 期望滚动到哪个的位置
  //   return { x: 0, y: 0 };
  // },
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem("token");
  if (to.path === "/login" || token) {
    next();
  } else {
    next("/login");
  }
});

export default router;
