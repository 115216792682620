let baseUrl;
if (window.location.href.includes("https")) {
  let a = window.location.href.split("/");
  let Url = a[0] + a[1] + "//" + a[2];
  baseUrl = Url + "/api"; //线上
} else {
  // baseUrl = "http://192.168.3.36:8002/api"; //线上

  baseUrl = "http://teacher.sishengsports.com/api"; //线上
}

module.exports = { baseUrl };

// module.exports = { baseUrl };
