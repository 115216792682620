import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import store from "./stote/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/style/style.css";
import Vant from "vant";
import "vant/lib/index.css";
import "vue-area-linkage/dist/index.css"; // v2 or higher
import VueAreaLinkage from "vue-area-linkage";
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import Print from "vue-print-nb";
import JSZip from "jszip";
import FileSaver from "file-saver";
import moment from "moment";
import "./utils/flexible";
// 引入图标
import "./assets/icon/iconfont.css";
// import less from "less";
// Vue.use(less);
Vue.config.productionTip = false;
Vue.use(Print);
Vue.prototype.axios = axios;
Vue.prototype.$store = store;
Vue.prototype.JSZip = JSZip;
Vue.prototype.moment = moment;
Vue.prototype.FileSaver = FileSaver;
// axios.defaults.baseURL = "http://yqjyj.zbfib.com/api";
import { baseUrl } from "@/utils/config";
// axios.defaults.baseURL = "http://192.168.3.195:8000/api";
axios.defaults.baseURL = baseUrl;

// axios.defaults.baseURL = "http://jyj.sishengsports.com/api";
// axios.defaults.timeout = 600000;
// axios.defaults.baseURL = " http://192.168.2.3:8000/api";
// http://122.51.132.88/api/backend/login
axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem("token");
    // && config.url !== "/auth/login"
    if (token && config.url !== "/auth/login") {
      config.headers.Authorization = "Bearer " + token;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    // console.log(response)
    if (response.data.code === 401) {
      router.push({ path: "/login" });
    }
    if (response.data) {
      return response.data;
    } else {
      return response;
    }
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
Vue.prototype.getQueryValue = function (name) {
  var url = window.location.href;
  var result = url.match(new RegExp("[?&]" + name + "=([^&]+)", "i"));
  if (result == null || result.length < 1) {
    return "";
  }
  return decodeURI(result[1]);
};

Vue.filter("filterTime", (t) => {
  //数据处理的过程
  let h = parseInt((t / 60 / 60) % 24);
  let m = parseInt((t / 60) % 60);
  let s = parseInt(t % 60);
  // 因为h已经是数字型了，如果0不加引号就变成加法了
  h = h < 10 ? "0" + h : h;
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return `${m}分${s}秒`;
});
Vue.filter("filterTimeDate", (t) => {
  //数据处理的过程
  let h = parseInt((t / 60 / 60) % 24);
  let m = parseInt((t / 60) % 60);
  let s = parseInt(t % 60);
  // 因为h已经是数字型了，如果0不加引号就变成加法了
  h = h < 10 ? "0" + h : h; // eslint-disable-line no-unused-vars
  m = m < 10 ? "0" + m : m;
  s = s < 10 ? "0" + s : s;
  return `${m}’${s}’’`;
});
Vue.use(ElementUI);
Vue.use(VueQuillEditor);
Vue.use(VueAreaLinkage);
Vue.use(Vant);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
