<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return { beforeUnload: "", Handler: "" };
  },
};
</script>

<style>
html {
}
.bjdkd {
  /* width: 100px; */
  /* height: 38px; */
  background: rgb(34, 107, 255);
  box-shadow: 4px 16px 48px 0px rgba(111, 143, 234, 0.1);
  border-radius: 20px;
  cursor: pointer;
  /* line-height: 38px; */
  text-align: center;
  font-size: 20px;
  color: #f2f3f5;
  padding: 5px 10px;
  box-sizing: border-box;
}
.tdt-bottom {
  display: none !important;
}
.flexSe {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.fllexSb {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.search_left {
  position: relative;
  width: 272px;
}
.search_left img {
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
.search_left .el-input .el-input__inner {
  border-radius: 10px;
  background-color: #f2f3f5;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0px !important;
}
html,
body {
  min-height: 100vh;
}

#app {
  min-height: 100vh;
}
.flexSt {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.flexSb {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.flexSc {
  align-items: center;
  display: flex;
  justify-content: center;
}
.el-dialog {
  border-radius: 13px !important;
}

.el-dialog .el-dialog__header {
  border-bottom: 1px #e4edfc solid;
}
.el-dialog .el-dialog__title {
  border-left: #537af2 solid 2px;
  padding-left: 10px;
}
</style>
