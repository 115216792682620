const teacherEvaluation = () =>
  import("../views/evaluation/teacher/teacherEvaluation");
const teacherlist = () => import("../views/evaluation/teacher/teacherlist");
const teacherevaluationdetail = () =>
  import("../views/evaluation/teacher/teacherEvaluationDetail");
// const evaluation = () => import("../views/evaluation/evaluation");
const studentLiteracy = () =>
  import("../views/evaluation/student/studentLiteracy");
const studentLiteracydetail = () =>
  import("../views/evaluation/student/studentLiteracyDetail");
const studentlist = () => import("../views/evaluation/student/studentlist");

const teachingModel = () =>
  import("../views/evaluation/teachingModel/teachingModel");
// const editTeacher = () => import("../views/evaluation/editTeacher");
// const editStudent = () =>
//   import("../views/evaluation/studentLiteracy/editStudent");

const gradelist = () => import("../views/evaluation/student/gradeList");
const classlist = () => import("../views/evaluation/student/classList");

const layout = () =>
  import(/* webpackChunkName: "group-device" */ "@/views/home.vue");
export default [
  {
    path: "/evaluation",
    component: layout,
    name: "evaluation",
    children: [
      {
        path: "/classlist",
        component: classlist,
        name: "classlist",
      },
      {
        path: "/gradelist",
        component: gradelist,
        name: "gradelist",
      },
      // {
      //   path: "/editTeacher",
      //   component: editTeacher,
      //   name: "editTeacher",
      // },

      // {
      //   path: "/editStudent",
      //   component: editStudent,
      //   name: "editStudent",
      // },

      {
        path: "/teacherlist",
        component: teacherlist,
        name: "teacherlist",
      },
      {
        path: "/studentlist",
        component: studentlist,
        name: "studentlist",
      },

      {
        path: "/teacherEvaluation",
        component: teacherEvaluation,
        name: "teacherEvaluation",
      },
      {
        path: "/teacherevaluationdetail",
        component: teacherevaluationdetail,
        name: "teacherevaluationdetail",
      },
      {
        path: "/studentLiteracy",
        component: studentLiteracy,
        name: "studentLiteracy",
      },
      {
        path: "/studentLiteracydetail",
        component: studentLiteracydetail,
        name: "studentLiteracydetail",
      },
      {
        path: "/teachingModel",
        component: teachingModel,
        name: "teachingModel",
      },
    ],
  },
];
